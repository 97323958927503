<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/content.svg" alt="" />
        <span class="center" @click="goBack">内容管理</span>
        <img src="../../assets/right.png" alt="" />
        <span class="center">新增内容</span>
      </div>
    </div>
    <el-card>
      <el-form
        label-position="right"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="60px"
        style="text-align:left"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            style="width: 600px"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="类型" prop="type">
          <el-autocomplete
            style="width: 600px"
            class="inline-input"
            v-model="ruleForm.type"
            :fetch-suggestions="querySearch"
            placeholder="请输入类型"
            @select="handleSelect"
            clearable
          ></el-autocomplete>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="ruleForm.remark"
            style="width: 600px"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>

        <el-form-item label="内容" prop="text">
          <TinyEditor
            v-model="ruleForm.text"
            :height="300"
            :configStr="''"
          ></TinyEditor>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import { api } from '../../api/base/index';
// 新增接口
export const ContentAdd = api('/content')('content.add.json');
// 查询列表接口
export const ContentList = api('/content')('content.list.json');
// 查询详情接口
export const ContentDetail = api('/content')('content.describe.json');
// 编辑接口
export const ContentEdit = api('/content')('content.edit.json');
// 删除接口
export const ContentDel = api('/content')('content.del.json');
export const ContentTypeList = api('/content')('content.type.list.json');

export default {
  components: {
    TinyEditor: () => import('../../components/tinyEditor')
  },
  data() {
    return {
      ruleForm: {
        name: '',
        type: '',
        text: '',
        remark: ''
      },
      rules: {
        name: [{ required: true, message: '请输入内容名称', trigger: 'blur' }],
        type: [{ required: true, message: '请输入内容类型', trigger: 'blur' }],
        text: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入内容备注', trigger: 'blur' }]
      },
      restaurants: []
    };
  },
  props: {
    dialogVisible: Boolean
  },
  created() {
    this.getTypeList();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          ContentAdd(this.ruleForm).then(() => {
            this.close(formName);
            window.history.back(-1);
          });
        } else {
          return false;
        }
      });
    },
    close(formName) {
      this.$refs[formName].resetFields();
    },
    goBack() {
      window.history.back(-1);
    },
    async getTypeList() {
      const res = await ContentTypeList();
      this.restaurants = res.map(it => {
        return { value: it };
      });
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      console.log(item);
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.el-form-item {
  margin-bottom: 22px;
}
</style>
